import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../assets/styles.css';
import logo from '../assets/logo.png';
import axios from 'axios';
import { FaInstagram } from 'react-icons/fa';
import  ContactForm  from './ContactForm';

function HomePage() {
    const history = useHistory();

    return (
        <div className="home-container">
            <div className="title">
                <img src={logo} alt="Whisper Cleaning Logo" className="logo"/>
                <h1>Whisper Cleaning</h1>
                <h2 className="tagline">Where Quality Speaks for Itself</h2>
            </div>
            <ContactForm />
            <div className="social-media">
                <a href="https://www.instagram.com/whisper.cleaning/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={48}/>
                </a>
            </div>
        </div>
    );
}

export default HomePage;
