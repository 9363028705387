// ErrorMessage.js
import React from 'react';

const ErrorMessage = ({ message }) => {
    return (
        <p className="error-message">
            {message} Please contact us via <a href="https://wa.me/+31629105438">WhatsApp</a> or <a href="https://www.instagram.com/whisper.cleaning/">Instagram</a>.
        </p>
    );
};

export default ErrorMessage;