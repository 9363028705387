import React, { useState } from 'react';
import axios from 'axios';
import ErrorMessage from './ContactFormError';
import ContactFormError from "./ContactFormError";


function ContactForm() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const formatRussianNumber = (phoneNumber) => {
        if (!phoneNumber) return '';

        // Remove leading '8' and replace it with '7'
        if (phoneNumber.startsWith('8')) {
            phoneNumber = '7' + phoneNumber.slice(1);
        }
        // Remove leading '9' and replace it with '79'
        if (phoneNumber.startsWith('9')) {
            phoneNumber = '79' + phoneNumber.slice(1);
        }

        let formattedNumber = '+7';

        if (phoneNumber.length > 1) {
            formattedNumber += ' (';
            let areaCode = phoneNumber.slice(1, 4);
            formattedNumber += areaCode;

            if (phoneNumber.length >= 4) {
                formattedNumber += ') ';
            }
        }

        if (phoneNumber.length >= 4) {
            let firstPart = phoneNumber.slice(4, 7);
            formattedNumber += firstPart;
        } else if (phoneNumber.length > 4) {
            let firstPart = phoneNumber.slice(4);
            formattedNumber += firstPart;
        }

        if (phoneNumber.length >= 7) {
            let secondPart = phoneNumber.slice(7, 9);
            formattedNumber += '-' + secondPart;
        } else if (phoneNumber.length > 7) {
            let secondPart = phoneNumber.slice(7);
            formattedNumber += '-' + secondPart;
        }

        if (phoneNumber.length >= 9) {
            let thirdPart = phoneNumber.slice(9, 11);
            formattedNumber += '-' + thirdPart;
        } else if (phoneNumber.length > 9) {
            let thirdPart = phoneNumber.slice(9);
            formattedNumber += '-' + thirdPart;
        }

        return formattedNumber;
    };

    const formatDutchNumber = (phoneNumber) => {
        if (!phoneNumber) return '';

        // Normalize phone number to start with '31'
        if (phoneNumber.startsWith('31')) {
            phoneNumber = phoneNumber;
        } else if (phoneNumber.startsWith('06')) {
            phoneNumber = '31' + phoneNumber.slice(1);
        } else if (phoneNumber.startsWith('6')) {
            phoneNumber = '31' + phoneNumber;
        }

        // Remove country code '31' for formatting
        let countryCode = '+31';
        phoneNumber = phoneNumber.slice(2); // Remove '31'

        let formattedNumber = countryCode;

        if (phoneNumber.length > 0) {
            formattedNumber += ' ' + phoneNumber.charAt(0); // '6'
        }

        if (phoneNumber.length >= 3) {
            formattedNumber += ' ' + phoneNumber.slice(1, 3);
        } else if (phoneNumber.length > 1) {
            formattedNumber += ' ' + phoneNumber.slice(1);
        }

        if (phoneNumber.length >= 5) {
            formattedNumber += ' ' + phoneNumber.slice(3, 5);
        } else if (phoneNumber.length > 3) {
            formattedNumber += ' ' + phoneNumber.slice(3);
        }

        if (phoneNumber.length > 5) {
            formattedNumber += ' ' + phoneNumber.slice(5);
        }

        return formattedNumber;
    };


    const formatPhoneNumber = (value) => {
        // Remove all non-digit characters
        const phoneNumber = value.replace(/\D/g, '');

        if (!phoneNumber) {
            // If the input is empty after removing non-digits, return an empty string
            return '';
        }

        if (phoneNumber.startsWith('7') || phoneNumber.startsWith('8') || phoneNumber.startsWith('9')) {
            // Russian number
            return formatRussianNumber(phoneNumber);
        } else if (
            phoneNumber.startsWith('31') ||
            phoneNumber.startsWith('6') ||
            phoneNumber.startsWith('06')
        ) {
            // Dutch number
            return formatDutchNumber(phoneNumber);
        } else {
            // If input does not match known patterns, return the unformatted number
            return value;
        }
    };

    // Function to validate phone number
    const validatePhoneNumber = (value) => {
        const phoneNumber = value.replace(/\D/g, '');

        // Validate Russian numbers
        const isRussian = phoneNumber.match(/^7\d{10}$/) || phoneNumber.match(/^8\d{10}$/);

        // Validate Dutch mobile numbers with country code
        const isDutchMobile = phoneNumber.match(/^316\d{8}$/);

        // Validate local Dutch mobile numbers starting with '06'
        const isLocalDutchMobile = phoneNumber.match(/^06\d{8}$/);

        return isRussian || isDutchMobile || isLocalDutchMobile;
    };

    const handleChange = (e) => {
        let input = e.target.value;

        try {
            let formattedPhoneNumber = formatPhoneNumber(input);
            setPhoneNumber(formattedPhoneNumber);
        } catch (error) {
            // If formatting fails, set the input as it is
            setPhoneNumber(input);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePhoneNumber(phoneNumber)) {
            setError('Please enter a valid mobile phone number');
            setStatus('');
            return;
        } else {
            setError('');
            setStatus('');
        }

        // Submission logic
        try {
            const response = await axios.post('/api/contact', { phoneNumber });
            setStatus('Thank you! We will contact you soon.');
            setPhoneNumber(''); // Reset the phone number field
        } catch (err) {
            setError(ErrorMessage({ message: "An error occurred." }));
        }
    };

    return (
        <div className="contact-form-container">
            <form onSubmit={handleSubmit}>
                <div className="input-button-wrapper">
                    <input
                        type="tel"
                        placeholder="Phone number"
                        value={phoneNumber}
                        onChange={handleChange}
                        onPaste={handleChange}
                        required
                    />
                    <button type="submit">Book</button>
                </div>
                {error && <p className="error-message">{error}</p>}
            </form>
            {status && <p className="status-message">{status}</p>}
        </div>
    );
}

export default ContactForm;
